import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { updateLegislation } from "../../actions/legislationActions";
import { TextField, Grid, Modal } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import useIsMounted from "react-is-mounted-hook";
import { supabase } from "../../actions/shared/api";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { TailSpin } from "react-loading-icons";
import { options, diffStyles, diffTemplates } from "./legislationDiffOptions";
import { EditorComponents } from "./editorComponents";

const LegislationEditor = () => {
  const [loading, isLoading] = useState(true); // app starts in a loading state
  const [content, updateContent] = useState("");
  const [legislationID, setLegislationID] = useState(null);
  const [legislationName, setLegislationName] = useState("");
  const [savingStyle, updateSavingStyle] = useState("none");
  const [modalOpen, setModalOpen] = useState(false);
  const isMounted = useIsMounted();
  const email = localStorage.getItem("email");
  const [diffStyle, setDiffStyle] = useState(null);
  const [editorKey, setEditorKey] = useState(1);
  const [template, setTemplate] = useState(null);
  const [issueID, setIssueID] = useState(null);

  useEffect(() => {
    if (diffStyle) {
      const intervalId = setInterval(() => {
        const unwantedElement = document.querySelector('.Toastify__toast.Toastify__toast--error');
        if (unwantedElement) {
          unwantedElement.remove();
          clearInterval(intervalId);
        }
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [diffStyle]);

  const editorRef = useRef(null);
  const styles = {
    color: "green",
    display: savingStyle,
    float: "right",
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            // Check for the exact class or any unique identifier of the banner
            if (node.classList.contains('tox-notification--warning')) {
              // Check if the banner contains the specific message
              const bannerText = "This domain is not registered in the TinyMCE Customer Portal";
              if (node.textContent.includes(bannerText)) {
                node.remove();
              }
            }
          }
        });
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, []);

  const getLegislationIDFromParams = () => {
    var url = new URL(window.location);
    var existingLegislation = parseInt(url.searchParams.get("legislationID"));
    return existingLegislation;
  };

  const getContestIDFromParams = () => {
    var url = new URL(window.location);
    var contestID = parseInt(url.searchParams.get("contest"));
    return contestID;
  };

  const fetchExistingLegislation = async () => {
    const legislationID = getLegislationIDFromParams();

    const { data, error } = await supabase
      .from("legislation")
      .select("*")
      .match({ id: legislationID });

    if (error) {
      return error;
    } else {
      updateContent(data[0]?.content || "");
      setLegislationName(data[0]?.title || "");
      setLegislationID(legislationID);
      setDiffStyle(data[0]?.diffStyle || "");
      setEditorKey(editorKey + 1);
      isLoading(false);
    }
  };

  useEffect(() => {
    getLegislationIDFromParams();
    if (isMounted) {
      fetchExistingLegislation();
    }
    isLoading(false);
  }, [legislationID]);

  const handleContentUpdate = (theContent) => {
    updateContent(theContent);
  };

  const generatePDF = () => {
    setModalOpen(true);
    var iframe = document.querySelector(".tox-edit-area__iframe");

    var iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    var iframInnerContent = iframeDocument.querySelector("#tinymce");
    html2canvas(iframInnerContent).then(function (canvas) {
      let pdf = new jsPDF();
      let width = canvas.width;
      let height = canvas.height;
      //set the orientation
      if (width > height) {
        pdf = new jsPDF("l", "px", [width, height]);
      } else {
        pdf = new jsPDF("p", "px", [height, width]);
      }
      //then we get the dimensions from the 'pdf' file itself
      width = pdf.internal.pageSize.getWidth();
      height = pdf.internal.pageSize.getHeight();
      pdf.addImage(canvas, "PNG", 0, 0, width, height);
      // Add page numbers
      const pageCount = pdf.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        pdf.text(String(i), 196, 285);
      }
      pdf.save("download.pdf");
      setModalOpen(false);
    });
  };

  const updateLegislationName = (e) => {
    setLegislationName(e.target.value);
  };

  const handleSubmit = async () => {
    let url = new URL(window.location.href);
    const contestID = getContestIDFromParams();
    const theData = {
      content: content,
      title: legislationName,
      user: email,
      contest: contestID,
    };

    if (legislationID) {
      const { data, error } = await supabase
        .from("legislation")
        .update(theData)
        .match({ id: legislationID });
    } else {
      const { data, error } = await supabase
        .from("legislation")
        .insert([theData]);
      console.log("The id is " + data[0].id);
      setLegislationID(data[0].id);
      window.history.pushState({}, "", url + "&legislationID=" + data[0].id);
    }
    toast.success("Saved!");
  };

  const saveDiffStyle = async (diffStyle) => {
    const { data, error } = await supabase
      .from("legislation")
      .update({ diffStyle: diffStyle })
      .match({ id: legislationID });

    if (error) {
      toast.error(error.message);
    } else {

    }
  }

  const getContestIDFromURL = () => {
    const url = new URL(window.location.href);
    const contestID = url.searchParams.get("contest");
    return parseInt(contestID, 10);
  };

  const fetchIssueLegislationField = async (issueID) => {
    console.log("IssueID being passed:", issueID);

    if (typeof issueID !== "number" || issueID === null) {
      console.error("Invalid issueID provided:", issueID);
      return null;
    }

    try {
      const { data, error } = await supabase
        .from("issues")
        .select("legislation")
        .eq('id', issueID);

      if (error) {
        throw error;
      }

      console.log("Retrieved legislation:", data[0]?.legislation);
      return data[0]?.legislation;

    } catch (err) {
      console.error("Error fetching legislation needed:", err.message);
      return null;
    }
  };

  const generateText = (data) => {
    if (!data) {
      return "No data provided.";
    }
    return `Generated text for data: ${data}`;
  };

  const OPENAI_API_KEY = "sk-proj-fI2E4KJGHNEyPtZnv2J7T3BlbkFJ6WMxG8jqZxpboyehq96g";

  const handleOpenAIRequest = async () => {
    try {
      const contestID = getContestIDFromURL();

      if (!contestID || isNaN(contestID)) {
        console.error("Invalid IssueID/ContestID retrieved from the URL.");
        return;
      }

      const { data: issueData, error } = await supabase
        .from('issues')
        .select('legislation')
        .eq('id', contestID);

      if (error) {
        console.error("Error fetching related data from issues table:", error);
        return;
      }

      if (!issueData || !issueData.length) {
        console.error("No data received from issues table.");
        return;
      }

      const legislationFromField = issueData[0].legislation;
      const editorContent = content; // Get the current content from the editor
      const state = "Federal"; // Default to Federal if no state is specified
      const basePrompt = `This is ${state} level legislation. Start with detailed "Whereas" clauses that span multiple paragraphs, ensuring proper spacing. Next, add multiple "SECTION"s with detailed provisions making specific edits to the existing legal code of the state (or U.S. code if federal). For each section, provide the exact lines of the code that need to be edited and specify how they should be changed. For example: "2.2. Amend Section 102, Line 5 of the U.S. code as follows: [New text]". Then add a comprehensive Penalties section at the end. Ensure the entire legislation spans at least two pages. If you include an effective date, make sure it is in the format 20XX. After each Whereas clause, SECTION clause, Penalties section, Effective date, and Disclaimer, add an html <br> tag to ensure proper spacing.`;
      const finalPrompt = `${editorContent}\n\n${legislationFromField} ${basePrompt}`;

      console.log("Final Prompt:", finalPrompt);

      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-4o",
          temperature: 0,
          max_tokens: 8000,
          messages: [{ role: "user", content: finalPrompt }],
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("OpenAI request failed with status:", response.status, "and text:", errorText);
        if (response.status === 429) {
          toast.error("You have exceeded your current quota. Please check your plan and billing details.");
          const fallbackResponse = "Due to quota limits, we are unable to generate the legislation at this moment. Please try again later or contact support.";
          updateContent(fallbackResponse);
        }
        return;
      }

      const data = await response.json();
      console.log("OpenAI response data:", data);

      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        const legislationWithDisclaimer = data.choices[0].message.content + "\n\n**DISCLAIMER: This is a first draft to help you get started. Most State level legislation requires you to show in your Bill what the current legal code is, and how you want the language of the legal code updated, added to, or removed. If you would like to know more or give your feedback, please email tim@writelegislation.com.**";
        console.log("Legislation with Disclaimer:", legislationWithDisclaimer);
        updateContent(legislationWithDisclaimer);
        appendOpenAITextToDiffStyle(legislationWithDisclaimer);
      } else {
        console.error("Unexpected response format from OpenAI:", data);
      }
    } catch (error) {
      console.error("Error making request to OpenAI:", error);
      toast.error("An error occurred while making the request to OpenAI.");
    }
  };

  const handleSetDiffStyle = e => {
    updateContent(diffTemplates[e.target.value]);
    setDiffStyle(e.target.value);
    saveDiffStyle(e.target.value);
    setEditorKey(editorKey + 1);
  };

  const appendOpenAITextToDiffStyle = (legislationWithDisclaimer) => {
    if (diffStyle && legislationWithDisclaimer) {
      let diffTemplate = diffTemplates[diffStyle];
      const markerText = "If you select the button above to auto-generate legislation, the result will go here. Remember to be patient and wait 45 seconds or so. If you do not wish to auto-generate, then erase this and begin writing.";

      const markerRegex = new RegExp(`(<p class="numbered"[^>]*>)<strong>${markerText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}</strong>(</p>)`, "g");

      diffTemplate = diffTemplate.replace(markerRegex, function (fullMatch, openingTag, closingTag) {
        return `${openingTag}${legislationWithDisclaimer}${closingTag}`;
      });

      updateContent(diffTemplate);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        align="center"
        justify="center"
        direction="column"
      >
        <Grid pt={5} item>
          <p pt={5}>Loading...</p>
        </Grid>
      </Grid>
    );
  } else {
    console.log("Rendering content:", content);
    return (
      <div style={{ background: "white" }}>
        <EditorComponents
          key={editorKey}
          handleSubmit={handleSubmit}
          legislationName={legislationName}
          updateLegislationName={updateLegislationName}
          handleSetDiffStyle={handleSetDiffStyle}
          options={options}
          styles={styles}
          editorRef={editorRef}
          content={content}
          diffStyle={diffStyle}
          diffStyles={diffStyles}
          email={email}
          modalOpen={modalOpen}
          handleContentUpdate={handleContentUpdate}
          generatePDF={generatePDF}
          handleOpenAIRequest={handleOpenAIRequest}
        />
      </div>
    );
  }
};

function mapStateToProps(state) {
  const allState = state;
  let { legislation, auth } = state;
  const { isFetching } = legislation;
  legislation = legislation.legislation;
  const { isAuthenticated, email } = auth;

  return {
    isFetching,
    legislation,
    isAuthenticated,
    email,
    auth,
    allState,
  };
}

const mapDispatchToProps = {
  updateLegislation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegislationEditor);
