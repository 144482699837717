import React from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTwitterSquare, faGithubSquare, faInstagramSquare, faRedditSquare} from '@fortawesome/free-brands-svg-icons'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const Footer = () => (
    <footer className="footer">
        <Grid className="footer" container alignItems="center" justify="space-between">
            <Grid container item
                xs={12}
                md={4}
                justify="center">
                <Grid item
                    xs={12}
                    md={6}>
                    <Typography variant="body1" color="textSecondary" component="h4" align="center">
                        <div className="footer-text">© copyright 2025 turq, pbc</div>
                    </Typography>
                </Grid>
                <Grid item
                    xs={12}
                    md={6}>
                    <Typography variant="body1" color="textSecondary" component="h4" align="center">
                        <div className="footer-text">Contact: (336) 398-4048 or tim@writelegislation.com
                        </div>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item
                xs={12}
                md={4}
                alignItems="center"
                justify="center">
                <Grid item>
                    <a href="" target="_blank" rel="noopener noreferrer" className="mx-2">
                        <FontAwesomeIcon className="footer-text" size="2x"
                            icon={faTwitterSquare}/>
                    </a>
                </Grid>
                <Grid item>
                    <a href="" target="_blank" rel="noopener noreferrer" className="mx-2">
                        <FontAwesomeIcon className="footer-text" size="2x"
                            icon={faInstagramSquare}/>
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://github.com/TurqPBC" target="_blank" rel="noopener noreferrer" className="mx-2">
                        <FontAwesomeIcon className="footer-text" size="2x"
                            icon={faGithubSquare}/>
                    </a>
                </Grid>
                <Grid item>
                    <a href="" target="_blank" rel="noopener noreferrer" className="mx-2">
                        <FontAwesomeIcon className="footer-text" size="2x"
                            icon={faRedditSquare}/>
                    </a>
                </Grid>
            </Grid>
            <Grid container item
                xs={12}
                md={4}
                justify="center">
                <Grid item>
                    <Typography variant="body1" color="textSecondary" component="h4" align="center">
                        <a className="footer-link" href="https://writelegislation.com/terms/Terms_of_Service.pdf">Terms of Service</a>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </footer>
)

export default Footer
